// Lazy load youtube videos with thumbnail image
( () => {
  const ytVideos = Array.from(document.querySelectorAll( '.video-youtube' ));

  ytVideos.forEach( (video) => {

    video.addEventListener( 'click', () => {
      const iframe = document.createElement( 'iframe' );

      iframe.setAttribute( 'frameborder', '0' );
      iframe.setAttribute( 'allowfullscreen', '' );
      iframe.setAttribute( 'src', 'https://www.youtube.com/embed/'+ video.dataset.embed +'?rel=0&showinfo=0&autoplay=1' );

      video.innerHTML = '';
      video.appendChild( iframe );
    });
  });
} )();
