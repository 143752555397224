/**
 * Avalanche Region Selector
 *
 * - triggers a takeover for region selection
 * - locks body scroll when takeover is open
 * - traps focus when takeover is open
 * - binds escape key to close takeover when it is open
 */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

(function () {
  const regionSelectorTrigger = document.querySelector('[data-js="region-selector-trigger"]');
  const regionSelectorCloseBtn = document.querySelector('[data-js="region-selector-close-btn"]');
  const regionSelectorContainer = document.querySelector('[data-js="region-selector"]');

  function init() {
    if (regionSelectorTrigger) {
      bindEvents();
    }
  }

  function bindEvents() {
    regionSelectorTrigger.addEventListener('click', openRegionSelector);
    regionSelectorCloseBtn.addEventListener('click', closeRegionSelector);
    regionSelectorContainer.addEventListener('keydown', handleRegionSelectorKeyPress);
  }

  // Open Region Selector
  function openRegionSelector() {
    regionSelectorTrigger.setAttribute('aria-expanded', 'true');
    regionSelectorContainer.classList.add('region-selector--is-open');
    regionSelectorCloseBtn.focus({ preventScroll: true });
    trapFocus();
    disableBodyScroll(regionSelectorContainer);
  }

  // Close Region Selector
  function closeRegionSelector() {
    regionSelectorTrigger.setAttribute('aria-expanded', 'false');
    regionSelectorContainer.classList.remove('region-selector--is-open');
    regionSelectorTrigger.focus({ preventScroll: true });
    enableBodyScroll(regionSelectorContainer);
  }

  // Trap Focus in Region Selector
  function trapFocus() {
    const focusableEls = regionSelectorContainer.querySelectorAll('a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select');
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];

    regionSelectorContainer.addEventListener('keydown', function (e) {
      var isTabPressed = (e.key === 'Tab' || e.keyCode === 9);

      if (!isTabPressed) {
        return;
      }
      if (e.shiftKey) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    });
  }

  // Close Region Selector if escape key is pressed
  function handleRegionSelectorKeyPress(e) {
    if (e.key === 'Escape' || e.keyCode === 27) {
      closeRegionSelector();
    }
  }

  return init();
})();
